var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{staticClass:"mb-1 mt-1",attrs:{"columns":_vm.columns,"rows":_vm.rows,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'priceForRent')?_c('span',[_vm._v(" "+_vm._s(Number(props.row[props.column.field]).toLocaleString())+" "),(props.row.bed)?_c('span',[_vm._v("("+_vm._s(props.row.bed.name)+")")]):_vm._e()]):(props.column.field === 'deposit')?_c('span',[_vm._v(" "+_vm._s(Number(props.row[props.column.field]).toLocaleString())+" ")]):(props.column.field === 'tenant')?_c('span',[(props.row.tenant)?_c('span',[_vm._v(_vm._s(props.row.tenant.name))]):_vm._e()]):(['depositDate', 'checkinDate'].includes(props.column.field))?_c('span',[_vm._v(" "+_vm._s(_vm.parseDateToString(props.row[props.column.field]))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"loadingContent"},[_c('b-spinner',{attrs:{"label":"Loading","type":"grow"}})],1),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" "+_vm._s(_vm.t('Không có bản ghi nào!'))+" ")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }